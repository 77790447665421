defineDs('DanskeSpil/Framework/AppUniverse/Scripts/AppUniverse/Components/VersionSelector',
  [
    'Common/Framework/EventHandling/Scripts/OutsideClick',
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render'
  ],
  function (OutsideClick, m, Render) {

    // Template:
    var VersionSelector = function (controller) {
      // Variables:
      var render = Render();

      // Functions:
      var toggleSelector = function (state) {
        controller.property('toggleSelector', {
          name: 'device',
          state: state || !controller.property('toggleSelector')['state']
        });
      };

      var selectOption = function (option) {
        controller.selectedOption = option;
        controller.deviceSelectorUpdate(option);
        controller.property('swipperInit', false);
        toggleSelector(false);

      };

      var isActiveOption = function () {
        return controller.optionsList &&
          controller.optionsList.length &&
          controller.property('toggleSelector')['name'] === 'device' &&
          controller.property('toggleSelector')['state'];
      };

      var selectorDOM = function (label) {
        var placeHolderText = controller.selectedOption.displayName || controller.selectedOption;

        return m('.selector-wrapper', [
          m('span.selector-label', label),
          m('.options-wrapper', {
            class: isActiveOption() ? 'active' : '',
            config: render.depend('options-wrapper')
          }, [
            m('.options-placeholder-wrapper', {
              onclick: function () {
                toggleSelector();
              }
            }, [
              m('span.placeholder-text', placeHolderText),
              m('span.placeholder-icon')
            ]),
            m('.options-list-wrapper',
              controller.optionsList.map(function (option) {
                if (option.name) {
                  return m('.option-item-wrapper', {
                    class: controller.selectedOption === option ? 'selected' : '',
                    onclick: function () {
                      selectOption(option);
                    }
                  }, [
                    m('span.option-item-text', option.displayName),
                    m('span.option-item-icon')
                  ]);
                }
              })
            )
          ])
        ]);
      };

      // Render:
      render.ready.then(function (nodes) {
        var optionsWrapper = nodes['options-wrapper'];
        if (optionsWrapper) {
          var option = optionsWrapper;
          if (option.$element.classList.contains('active')) {
            OutsideClick(option.$element, option.context, function () {
              if (option.$element.classList.contains('active')) {
                controller.property('toggleSelector')['state'] = false;
              }
            });
          }
        }
      });

      // Return:
      return m('.app-installation-guide__version-selector',
        {
          config: render.depend('app-installation-guide__version-selector')
        },
        controller.deviceSelectorLabel() ? selectorDOM(controller.deviceSelectorLabel()) : null);
    };

    // Public functions:
    return VersionSelector;

  });
