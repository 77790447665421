defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/Components/RulesAndTerms', function () {
  const clearInput = document.querySelector('.rules-and-terms__input-clear');
  const rulesComponent = document.querySelector('.rules-and-terms');
  const rulesEl = document.querySelector('.rules-and-terms__rules');
  const searchEl = document.querySelector('.rules-and-terms__input');
  let sections;
  let toc;

  // Escape regular expressions to regular text.
  const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const updateRules = (searchString = '') => {
    searchString = escapeRegExp(searchString.trim());
    const searchRegex = new RegExp(searchString, 'gi');
    let texts = sections;

    if (searchString?.length > 1) {
      texts = texts.map((text) => {
        const matches = [];
        let match;

        // Index all html tags in the string.
        const allTags = [...text.matchAll(new RegExp('(<([^>]+)>)', 'gi'))].map((m) => ({
          start: m.index,
          end: m.index + m[0].length
        }));

        // If the searched word(s) is not part of an html tag, add its match details to the matches array.
        // Limit the loop to 1000 runs, just in case...
        let maxRuns = 1000;
        while (maxRuns-- && (match = searchRegex.exec(text)) !== null) {
          const insideTag = allTags.some((tag) => match.index >= tag.start && match.index + match[0].length <= tag.end);
          if (insideTag) continue;
          matches.push({
            matchBegin: match.index,
            matchEnd: match.index + match[0].length,
            match: match[0]
          });
        }

        // Reverse the array, so we start replacing matches from the end of the text string.
        // That way we don't mess up our index positions.
        matches?.reverse().forEach((m) => {
          text = text.substring(0, m.matchBegin) + `<mark>${m.match}</mark>` + text.substring(m.matchEnd);
        });

        return text;
      });

      // Filter out sections that don't contain the word(s) that the user searched for.
      texts = texts.filter((text) => text.includes('<mark>'));
    }

    // Add id to headers tag.
    texts = texts.map((textSection) => {
      const header = textSection.match(/(<h[23])>(.*)<\/h[23]>/);
      if (!header) return textSection;
      return textSection.replace(/<h[23]>/, `${header[1]} id="${header[2]}">`);
    });

    // Add texts back to the component.
    rulesEl.innerHTML = '';
    texts.forEach((textSection) => {
      rulesEl.innerHTML += textSection;
    });
  };

  const addEventListeners = () => {
    const toggle = document.querySelector('.rules-and-terms__toggle');

    clearInput.addEventListener('click', () => {
      rulesComponent.classList.remove('rules-and-terms--open', 'rules-and-terms--search');
      updateRules('');
      searchEl.value = '';
    });

    toggle.addEventListener('click', () => {
      rulesComponent.classList.toggle('rules-and-terms--open');
    });

    searchEl.addEventListener('keyup', () => {
      rulesComponent.classList.remove('rules-and-terms--open');
      updateRules(searchEl.value);
      searchEl.value ? rulesComponent.classList.add('rules-and-terms--search') : rulesComponent.classList.remove('rules-and-terms--search');
    });
  };

  const getRulesSections = (rules) => {
    let sections = rules.split('<section>');
    sections = sections.filter((t) => t.trim());
    sections = sections.map((section) => '<section>' + section);
    return sections;
  };

  const getToc = () => {
    let toc = [];
    sections.forEach((section) => {
      const headerTag = section.match(/<h[23]>(.*)<\/h[23]>/);
      if (headerTag) {
        toc.push(`<li><a href="#${headerTag[1]}">${headerTag[1]}</li>`);
      }
    });
    return toc;
  };

  const addTocs = () => {
    const tocEl = document.querySelector('.rules-and-terms__toc');
    const tocMobileEl = document.querySelector('.rules-and-terms__toc-mobile');

    toc.forEach((li) => {
      tocEl.innerHTML += li;
      tocMobileEl.innerHTML += li;
    });

    const tocLinks = document.querySelectorAll(
      '.rules-and-terms__toc-mobile a, .rules-and-terms__toc a'
    );

    tocLinks.forEach((a) => {
      a.addEventListener('click', () => {
        rulesComponent.classList.remove('rules-and-terms--open', 'rules-and-terms--search');
        searchEl.value = '';
        updateRules('');
      });
    });
  };

  const initialize = () => {
    if (document.querySelector('.mode-edit') || !rulesComponent || !rulesEl.innerHTML) return;

    addEventListeners();
    sections = getRulesSections(rulesEl.innerHTML);
    toc = getToc(sections);
    addTocs(toc);
  };

  initialize();

});

