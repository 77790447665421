defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/ReadMoreSpot', [
  'Shared/Framework/Mithril/Scripts/Helpers/DOMUtils',
], function (DOMUtils) {

  var readMore = function () {
    var $readMoreSpots = document.querySelectorAll('.js-read-more-spot');

    $readMoreSpots.forEach(($spot) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      $spot.querySelector('.js-read-more-cta')?.addEventListener('click', () => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        $spot.classList.toggle('is-open');
        const buttonText =  $spot.classList.contains('is-open') ? 'Skjul' : 'Læs mere';
        $spot.querySelector('.js-read-more-text').textContent = buttonText; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      });
    });
  };
  DOMUtils.ready(function () {
    readMore();

  });
});











